import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IManagerCoursesFilter,
  ITableOptions,
  ManagerManualIdDeleteRequest,
  ManagerManualIdGetRequest,
  ManagerManualIdPostRequest,
  ManagerManualPostRequest,
  ManagerManualTreeGetRequest,
  ManagerManualTreePostRequest,
  ManualData,
  ManualTreeResource,
  ManagerKnowledgeCoursesGetRequest,
  ManualResource,
  ManagerUsefulCourseCourseIdGetParams,
  ManagerUsefulCourseCourseIdGetRequest,
  UsefulMaterialPaginator,
  IUsefulMaterialsFilter,
  ManagerUsefulCourseIdPostRequest,
  UsefulMaterialPostBody,
  FullUsefulMaterial,
  ManagerUsefulMaterialIdGetRequest,
  NameValueResource,
  ManagerCoursesSelectGetRequest,
  ManagerUsefulMaterialIdPatchRequest,
  UsefulMaterialPatchBody,
  ManagerUsefulMaterialMaterialIdCourseIdDeleteRequest,
  CourseFoldersPaginator,
  ManagerKnowledgeCourseIdGetRequest,
  KnowledgeTreeResource,
  ManagerKnowledgeCourseIdTreeCourseFolderIdGetRequest,
  ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest,
  ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdPatchRequest,
  ManagerKnowledgeCourseIdTreeCourseFolderIdFolderPostRequest,
  CreateFolderRequest,
  SaveFolderRequest,
  ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdDeleteRequest,
  ManagerKnowledgeCourseIdTreePatchRequest,
  ManagerKnowledgeCoursesCourseIdGetRequest,
  ManagerCoursesSelectGetParams, KnowledgeCoursesPaginatorResource,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с базой знаний со стороны менеджера
 * - получение списка курсов
 * - просмотр и изменение документов прикрепленных за выбранным курсом
 * - получение дерева методички наставника
 * - CRUD методички наставника
 */

@Module({
  dynamic: true,
  name: 'ManagerDepository',
  namespaced: true,
  store,
})
class ManagerDepository extends VuexModule {
  // ---------------------------- Courses ---------------------------- >>
  // Filter
  coursesFilter: IManagerCoursesFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setCoursesFilter (payload: ITableOptions) {
    this.coursesFilter = Object.assign({}, payload)
  }

  // Entities
  courses: KnowledgeCoursesPaginatorResource = GET_DEFAULT_PAGINATOR()
  course: NameValueResource | null = null

  @Mutation
  setCourses (payload: KnowledgeCoursesPaginatorResource) {
    this.courses = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCourses(all = false) {
    const { data } = await ManagerKnowledgeCoursesGetRequest(tableOptionsToParams(all ? { ...this.coursesFilter, itemsPerPage: 25, page: 1 } : this.coursesFilter))
    this.setCourses(data)
    return data
  }

  @Mutation
  setCourse(payload: NameValueResource) {
    this.course = payload
  }

  @Action({ rawError: true })
  async fetchCourse(courseID: number) {
    const { data } = await ManagerKnowledgeCoursesCourseIdGetRequest(courseID)

    // Очищаем фильтры материалов и учебных файлов, если ид курса не совпадает с текущим
    if (this.course?.value && courseID !== this.course.value) {
      this.setMaterialsFilter({
        ...GET_DEFAULT_TABLE_FILTER(),
        itemsPerPage: 10,
      })
      this.setTrainingFilesFilter({
        ...GET_DEFAULT_TABLE_FILTER(),
        itemsPerPage: 10,
      })
    }

    this.setCourse(data)
    return data
  }

  // ---------------------------- Manuals ---------------------------- >>

  manualTree: ManualTreeResource[] = []

  @Mutation
  setManualTree (payload: ManualTreeResource[]) {
    this.manualTree = [...payload]
  }

  @Action({ rawError: true })
  async fetchManualTree () {
    const { data } = await ManagerManualTreeGetRequest()
    this.setManualTree(data)
    return data
  }

  @Action({ rawError: true })
  async saveManualTree (payload: ManualTreeResource[]) {
    const { data } = await ManagerManualTreePostRequest(payload)
    this.setManualTree(data)
    return data
  }

  // ---------------------------- Manual ---------------------------- >>

  manual: ManualResource | null = null

  // Note: null - для сброса значения
  // Данный сеттер нужен только в одном месте - для отображения текущего выбранного мануала
  // в мобильной/палншетной версии шапки раздела /manager/bank/depository/manual
  @Mutation
  setManual (payload: ManualResource | null) {
    if (payload) {
      this.manual = Object.assign({}, payload)
    } else {
      // Case: null
      this.manual = payload
    }
  }

  @Action({ rawError: true })
  async fetchManual (manualID: number) {
    const { data } = await ManagerManualIdGetRequest(manualID)
    this.setManual(data)
    return data
  }

  @Action({ rawError: true })
  async saveManual (payload: { manualID?: number, params: ManualData }) {
    if (payload.manualID) {
      const { data } = await ManagerManualIdPostRequest(payload.manualID, payload.params)
      this.setManual(data)
      return data
    } else {
      const { data } = await ManagerManualPostRequest(payload.params)
      this.setManual(data)
      return data
    }
  }

  @Action({ rawError: true })
  async deleteManual (manualID: number) {
    const { data } = await ManagerManualIdDeleteRequest(manualID)
    return data
  }

  // ---------------------------- Useful materials ---------------------------- >>

  // Filter
  materialsFilter: IUsefulMaterialsFilter = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  @Mutation
  setMaterialsFilter(payload: IUsefulMaterialsFilter) {
    this.materialsFilter = Object.assign({}, payload)
  }

  // Entities
  currentMaterial: FullUsefulMaterial | null = null
  materials: UsefulMaterialPaginator = GET_DEFAULT_PAGINATOR()
  materialCourses: NameValueResource[] = []

  // Получить материалы
  @Mutation
  setUsefulMaterials(payload: UsefulMaterialPaginator) {
    this.materials = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchUsefulMaterials(payload: { courseId: number, params?: ManagerUsefulCourseCourseIdGetParams }) {
    const { data } = await ManagerUsefulCourseCourseIdGetRequest(payload.courseId, tableOptionsToParams(this.materialsFilter))
    this.setUsefulMaterials(data)
    return data
  }
  // -- Получить материалы --

  // Получить список курсов
  @Mutation
  setMaterialCourses(payload: NameValueResource[]) {
    this.materialCourses = [...payload]
  }

  @Action({ rawError: true })
  async fetchMaterialCourses(params?: ManagerCoursesSelectGetParams) {
    const { data } = await ManagerCoursesSelectGetRequest(params)
    this.setMaterialCourses(data)
    return data
  }
  // -- Получить список курсов --

  // Создать материал
  @Action({ rawError: true })
  async createMaterial(payload: { courseId: number, body: UsefulMaterialPostBody}) {
    const { data } = await ManagerUsefulCourseIdPostRequest(payload.courseId, payload.body)
    return data
  }
  // -- Создать материал --

  // Получить материал
  @Mutation
  setMaterial(payload: FullUsefulMaterial) {
    this.currentMaterial = payload
  }

  @Action({ rawError: true })
  async fetchMaterial(materialId: number) {
    const { data } = await ManagerUsefulMaterialIdGetRequest(materialId)
    this.setMaterial(data)
    return data
  }
  // -- Получить материал --

  // Редактировать материал
  @Action({ rawError: true })
  async updateMaterial(payload: { materialId: number, body: UsefulMaterialPatchBody}) {
    const { data } = await ManagerUsefulMaterialIdPatchRequest(payload.materialId, payload.body)
    this.setMaterial(data)
    return data
  }
  // -- Редактировать материал --

  // Удалить материал
  @Mutation
  unsetMaterial() {
    this.currentMaterial = null
  }

  @Action({ rawError: true })
  async removeMaterial(payload: { courseId: number, materialId: number }) {
    const { data } = await ManagerUsefulMaterialMaterialIdCourseIdDeleteRequest(payload.materialId, payload.courseId)
    this.unsetMaterial()
    return data
  }
  // -- Удалить материал --

  // ---------------------------- Useful materials ---------------------------- >>

  // Filter
  trainingFilesFilter: ITableOptions = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  @Mutation
  setTrainingFilesFilter(payload: ITableOptions) {
    this.trainingFilesFilter = Object.assign({}, payload)
  }

  // Entities
  trainingFilesMonths: CourseFoldersPaginator = GET_DEFAULT_PAGINATOR()
  foldersTree: { folders: KnowledgeTreeResource[], monthID: number | null } = {
    folders: [],
    monthID: null,
  }

  // Получить папки месяцев курса учебных файлов
  @Mutation
  setTrainingFilesMonths(payload: CourseFoldersPaginator) {
    this.trainingFilesMonths = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchTrainingFilesMonths(payload: { courseId: number }) {
    const { data } = await ManagerKnowledgeCourseIdGetRequest(payload.courseId, tableOptionsToParams(this.trainingFilesFilter))
    this.setTrainingFilesMonths(data)
    return data
  }
  // -- Получить материалы --

  // Получить дерево папок месяца в курсе
  @Mutation
  setFoldersTree(payload: { folders: KnowledgeTreeResource[], monthID: number | null }) {
    this.foldersTree = {
      folders: payload.folders,
      monthID: payload.monthID,
    }
  }

  @Action({ rawError: true })
  async fetchFoldersTree(payload: { courseID: number, courseFolderID: number }) {
    const { data } = await ManagerKnowledgeCourseIdTreeCourseFolderIdGetRequest(payload.courseID, payload.courseFolderID)
    this.setFoldersTree({ folders: data, monthID: payload.courseFolderID })
    return data
  }
  // -- Получить дерево папок месяца в курсе --

  // Обновить дерево папок месяца в курсе
  @Action({ rawError: true })
  async updateFoldersTree(payload: { courseID: number, monthID: number, tree: KnowledgeTreeResource[] }) {
    const { data } = await ManagerKnowledgeCourseIdTreePatchRequest(payload.courseID, payload.tree)
    this.setFoldersTree({ folders: data, monthID: payload.monthID })
    return data
  }
  // -- Обновить дерево папок месяца в курсе --

  // Получить папку
  @Action({ rawError: true })
  async fetchFolder(payload: { courseID: number, courseFolderID: number, mediaFolderID: number }) {
    const { data } = await ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest(payload.courseID, payload.courseFolderID, payload.mediaFolderID)
    return data
  }
  // -- Получить папку --

  // Создать папку
  @Action({ rawError: true })
  async createFolder(payload: { body: CreateFolderRequest, courseID: number, courseFolderID: number }) {
    const { data } = await ManagerKnowledgeCourseIdTreeCourseFolderIdFolderPostRequest(payload.courseID, payload.courseFolderID, payload.body)
    return data
  }
  // -- Создать папку --

  // Обновить папку
  @Action({ rawError: true })
  async updateFolder(payload: { body: SaveFolderRequest, courseID: number, courseFolderID: number, mediaFolderID: number }) {
    const { data } = await ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdPatchRequest(payload.courseID, payload.courseFolderID, payload.mediaFolderID, payload.body)
    return data
  }
  // -- Обновить папку --

  // Удалить папку
  @Action({ rawError: true })
  async removeFolder(payload: { courseID: number, courseFolderID: number, mediaFolderID: number }) {
    const { data } = await ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdDeleteRequest(payload.courseID, payload.courseFolderID, payload.mediaFolderID)
    return data
  }
  // -- Удалить папку --

}

const ManagerDepositoryModule = getModule(ManagerDepository)

export default ManagerDepositoryModule
